<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="800" @click:outside="closeDialog()">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">About</v-card-title>
        <v-card-text style="text-align: justify">
          <h4 class="pt-4">Background/Description:</h4>
          <br>
          <p>The Copernicus Global Human Settlement Layer (GHSL) in this call for services aims at the production of a periodical update of the built-up surface layer for the years 2022, 2024, and 2026 and shall continue the pre-operational production of GHS-BUILT by JRC. It is intended to support existing Copernicus Emergency Management Service (EMS) components and to provide information to a broader user community to support scientific research and policy making. </p>
      <p>The Copernicus GHSL shall produce quality controlled geospatial built-up surface information with a global coverage including 3 product groups: Built-up surface layers, intermediate products, and post-processed products. </p>
        <p>The result of test mapping comprises the main Copernicus GHSL products for the production year 2022 within the AOIs provided in the ITT Annex 2: </p>
        <ul>
  <li><b>Built-up surface fraction </b></li>
  <li><b>Residential & Non-residential</b> layer</li>
  <li><b>Greenness</b> layer</li>
</ul>
<br>
<p>which are described in more detail below: </p>
<p><b>Built-up surface fraction (BUFRAC): </b></p>
<p>The BUFRAC is specified as a global 10 m raster layer with a data range of 0:1 containing continuous per-pixel information about the share of the raster pixel that is covered by a built-up surface. The built-up surface is defined as the informally called “building footprint”, which is the gross surface of buildings, meaning aboveground building constructions which are used for the shelter of humans, animals, things, the production of economic goods or the delivery of services and that refer to any structure constructed.  </p>
<p><b>Residential & Non-residential (RES/NRES) </b></p>

<p>The RES/NRES provides class labels within the BUFRAC > 0 area by subdividing the area into “residential” (RES built-up domain) = 1 and “non-residential” (NRES built-up domain) = 2. </p>

<p><b>Greenness </b></p>

<p>The greenness layer is a secondary layer expressing the maximum normalized difference vegetation index (NDVI) values from S-2 top of atmosphere (TOA) images per grid cell. It is provided globally with a spatial resolution of 10 m within morphological closures of BURFAC > 0 with circular structural elements of 300 m radius.
</p>

</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
export default {
  name: "InformationDialog",
  components: {},
  data() {
    return {};
  },
  computed: {
    dialog() {
      return this.$store.getters.getInformationDialog;
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit("setInformationDialog", false);
    },
  },
};
</script>

<style scoped>
.image {
  padding: 5px;
  /* background: white;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); */
  border-radius: 5px;
  text-align: -webkit-center;
}
</style>