<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" @click:outside="closeDialog()">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mobile View
        </v-card-title>
        <v-card-text style="text-align: justify">
          <h4 class="pt-4">Usage</h4>
          <p>
            The mobile view is only a limited version and does not support all
            features. For full functionality please open the application on a
            desktop computer.
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
export default {
  name: "MobileDialog",
  components: {},
  data() {
    return {};
  },
  computed: {
    dialog() {
      return this.$store.getters.getMobileDialog;
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit("setMobileDialog", false);
    },
  },
};
</script>

<style scoped>
</style>