import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

/* eslint-disable */
const store = new Vuex.Store({
  state: {
    startupDialog: false,
    informationDialog: false,
    mobileDialog: false,
  },
  mutations: {
    setStartupDialog(state, payload) {
      state.startupDialog = payload;
    },
    setInformationDialog(state, payload) {
      state.informationDialog = payload;
    },
    setMobileDialog(state, payload) {
      state.mobileDialog = payload;
    },
  },
  actions: {},
  getters: {
    getStartupDialog(state) {
      return state.startupDialog;
    },
    getInformationDialog(state) {
      return state.informationDialog;
    },
    getMobileDialog(state) {
      return state.mobileDialog;
    },
  },
});

/* eslint-enable */
export default store;
