<template>
  <div>
    <v-app-bar app permanent>
      <v-btn icon @click="logout()" height="64px">
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-logout</v-icon>
            </template>
            <span>Logout</span>
          </v-tooltip>
      </v-btn>
      <v-btn icon @click="openInformation()">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
            </template>
            <span>About</span>
          </v-tooltip>
      </v-btn>
      <v-spacer />
      <a href="https://www.geoville.com/" target="_blank" style="text-align: -webkit-right;">
          <v-img src="GeoVille_Logo.png" width="50%" />
        </a>
    </v-app-bar>
  </div>
</template>

<script>
import Vue from "vue"

export default {
  name: "AppBar",
  components: {},
  data() {
    return {
      mini: true,
    };
  },
  mounted() {
  },
  methods: {
    logout() {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      Vue.$keycloak.logout({ redirectUri: "http://localhost:8080/" });
    },
    openInformation() {
      this.$store.commit("setInformationDialog", true);
      document.getElementById('map').focus(); // Note: his is used to get rid of the gray backgrouns issue after the click event
    }
  },
};
</script>

<style scoped>

.fixedRight {
  position: fixed !important;
  right: 0 !important;
  width: 100%;
}
</style>
 