<template>
  <div id="map" style="z-index: 0">
    <v-card id="layermenu" width="17vw" class="container" style="background-color: rgba(255, 255, 255, 0.9)"
      @mouseover="disableMap()" @mouseleave="enableMap()" v-if="!isMobile">

      <div style="overflow-y: auto; overflow-x: hidden; max-height: 66vh">
        <v-expansion-panels multiple>
          <v-expansion-panel class="layer-panel">
            <v-expansion-panel-header>
              <div class="HeaderLabel">Base Layer</div>
            </v-expansion-panel-header>

            <v-expansion-panel-content fluid>
              <v-row style="margin: 0px">
                <v-col v-for="(element, id) in baseMaps" :key="id" style="max-width: fit-content; padding: 5px">
                  <v-img @click="setBaseMap(id)" :src="element.icon" class="basemap-icon" :style="[
                    element.active
                      ? { border: '3px solid #1e90b6' }
                      : { border: '3px solid #DDDDDD' },
                  ]" />
                </v-col>
              </v-row>
              <v-card id="map-attribution" v-html="getBaseMapAttribution()" flat></v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="layer-panel">
            <v-expansion-panel-header>
              <div class="HeaderLabel">Overlay Layer</div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>


              <v-card v-for="(element, id) in overlayMaps" :key="id" flat id="overlayCard">
                <v-expansion-panel>
                  <v-expansion-panel-header :hide-actions="!element.hasOwnProperty('opacity')">
                    <v-checkbox @click="updateOverlayMapsEvent(id, $event)" :ripple="false"
                      :input-value="element.displayed" class="checkboxOverlayMaps">

                      <!-- Custom v-checkbox label-->
                      <template v-slot:label>
                        <span>{{ overlayMaps[id].name }}
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon class="ml-0 mb-1" small v-bind="attrs" v-on="on">
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span v-html="overlayMaps[id].tooltipText"></span>
                          </v-tooltip>
                        </span>
                      </template>

                    </v-checkbox>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-slider v-if="element.hasOwnProperty('opacity')" v-model="element.opacity"
                      @change="setOpacity(id)" max="100" min="0" label="Opacity"></v-slider>
                    <div class="legend" v-if="legendData.hasOwnProperty(id)">
                      <div class="legend-padding-items" v-for="(item, key) in legendData[id]" :key="key">
                        <i v-if="item.color" class="colorcircle" :style="{ background: item.color }"></i>{{ item.name
                        }}<br />
                      </div>
                    </div>
                    <div v-if="legendDataGradient.hasOwnProperty(id)">
                      <p>{{legendDataGradient[id]['text']}}</p>
                      <v-img
                        :gradient="'to right, rgba(' + legendDataGradient[id]['start'] + '), rgba(' + legendDataGradient[id]['middle'] + '), rgba(' + legendDataGradient[id]['end'] + ')'"
                        min-height="20px">
                      </v-img>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>

  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-bing-layer";
import "leaflet.vectorgrid";
import "leaflet-geosearch/dist/geosearch.css"
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet-geosearch/dist/geosearch.css"
import "leaflet-spin"
import * as aoiData from "@/assets/testData.json";
import { eventBus } from '@/main.js';

// This code is required to show the marker icon on the geo-search event

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "Viewer2D",
  data() {
    return {
      map: null,
      mapConfiguration: {
        center: [47.594, 13.125],
        zoom: 13,
        zoomBlocked: 8,
        maxZoom: 19,
        minZooM: 2
      },
      items: ["Raw data", "Product A", "Product B", "Product C"],
      dataLayer: null,
      baseMaps: {
        osm: {
          type: "tileLayer",
          url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png",
          active: false,
          attribution:
            '&copy; <a href="http://www.openstreetmap.org/copyright" target="blank">OpenStreetMap</a>',
          icon: "osm-logo.png",
          mapObject: null,
        },
        "bing-sat": {
          type: "bing",
          active: true,
          attribution:
            "&copy; 2021 Microsoft Corporation, &copy; 2021 Maxar, &copy; CNES (2021) Distribution Airbus DS",
          icon: "bing-logo.png",
          mapObject: null,
          bingKey:
            "AjU2b-0kG-mPyaBkuT85URKHE9UbksBsK5Yo_EBqW3VkIWI4HhPTWl_Ze24_W4Jn",
        },
      },
      overlayMaps: {
        /*bezirke: {
          url: "https://tileserver.geoville.com/heatMap/gemeinden_statistics_Z1-16/%7Bz%7D/%7Bx%7D/%7By%7D.pbf/gemeinden_statistics_Z1-16/{z}/{x}/{y}.pbf",
          type: "vector",
          name: "Communities",
          zLevel: 400,
          mapObject: null,
          active: false,
          uniqueIdKey: "ID",
          tooltipText: '&copy; Bundesamt für Eich- und Vermessungswesen, statistics processed by GeoVille'
        },*/
        buildUpNonResOSM: {
          url: "https://tileserver.geoville.com/jrc-ghsl/proposal/combined_OSM_based_pred_NRES/{z}/{x}/{y}.png",
          type: "raster",
          name: "GHSL - RES/NRES (submitted version – OSM/Microsoft Footprints based)",
          zLevel: 308,
          displayed: false,
          active: false,
          mapObject: null,
          opacity: 100,
          tooltipText: "Copernicus GHSL test mapping by GeoVille, 2022"
        },
        buildUpSigmoidOSM: {
          url: "https://tileserver.geoville.com/jrc-ghsl/proposal/combined_OSM_based_pred_BUFRAC/{z}/{x}/{y}.png",
          type: "raster",
          name: "GHSL - BUFRAC (submitted version – OSM/Microsoft Footprints based)",
          zLevel: 307,
          displayed: false,
          active: false,
          mapObject: null,
          opacity: 100,
          readyForReport: false,
          product: "Imperviousness",
          route: "imperviousness",
          segment: null,
          text: "",
          tooltipText: "Copernicus GHSL test mapping by GeoVille, 2022"
        },
        greennessOSM: {
          url: "https://tileserver.geoville.com/jrc-ghsl/proposal/combined_OSM_based_pred_GREENNESS/{z}/{x}/{y}.png",
          type: "raster",
          name: "GHSL - Greenness (submitted version – OSM/Microsoft Footprints based)",
          zLevel: 306,
          displayed: false,
          active: false,
          mapObject: null,
          opacity: 100,
          tooltipText: "Copernicus GHSL test mapping by GeoVille, 2022"
        },
        buildUpNonResGHSL: {
          url: "https://tileserver.geoville.com/jrc-ghsl/proposal/combined_GHSL_based_pred_NRES/{z}/{x}/{y}.png",
          type: "raster",
          name: "GHSL - RES/NRES (alternative version - GHSL 2018 based)",
          zLevel: 305,
          displayed: false,
          active: false,
          mapObject: null,
          opacity: 100,
          tooltipText: "Copernicus GHSL test mapping by GeoVille, 2022"
        },
        buildUpSigmoidGHSL: {
          url: "https://tileserver.geoville.com/jrc-ghsl/proposal/combined_GHSL_based_pred_BUFRAC/{z}/{x}/{y}.png",
          type: "raster",
          name: "GHSL - BUFRAC (alternative version - GHSL 2018 based)",
          zLevel: 304,
          displayed: false,
          active: false,
          mapObject: null,
          opacity: 100,
          readyForReport: false,
          product: "Imperviousness",
          route: "imperviousness",
          segment: null,
          text: "",
          tooltipText: "Copernicus GHSL test mapping by GeoVille, 2022"
        },
        buildUpNonResJRC: {
          url: "https://tileserver.geoville.com/jrc-ghsl/proposal/combined_JRC_2018_NRES/{z}/{x}/{y}.png",
          type: "raster",
          name: "GHSL 2018 - RES/NRES (JRC version)",
          zLevel: 303,
          displayed: false,
          active: false,
          mapObject: null,
          opacity: 100,
          tooltipText: "© European Union, 1995-2022; produced by the EC JRC as open and free data"
        },
        buildUpSigmoidJRC: {
          url: "https://tileserver.geoville.com/jrc-ghsl/proposal/combined_JRC_2018_BUFRAC/{z}/{x}/{y}.png",
          type: "raster",
          name: "GHSL 2018 - BUFRAC (JRC version)",
          zLevel: 302,
          displayed: false,
          active: false,
          mapObject: null,
          opacity: 100,
          readyForReport: false,
          product: "Imperviousness",
          route: "imperviousness",
          segment: null,
          text: "",
          tooltipText: "© European Union, 1995-2022; produced by the EC JRC as open and free data"
        },


      },
      legendData: {
        buildUpNonResGHSL: {
          0: { name: "Residential", color: "#ff00ff00" },
          1: { name: "Non residential", color: "#ffb200" },
        },
        buildUpNonResJRC: {
          0: { name: "Residential", color: "#ff00ff00" },
          1: { name: "Non residential", color: "#ffb200" },
        },
        buildUpNonResOSM: {
          0: { name: "Residential", color: "#ff00ff00" },
          1: { name: "Non residential", color: "#ffb200" },
        }

      },
      legendDataGradient: {
        buildUpSigmoidGHSL: {
          text: "Built-up surface fraction (increasing share from 0.1 (left) to 1.0 (right))",
          start: "255,245,240,0",
          middle: "245, 81, 58, 1",
          end: "103,0,13,255"
        },
        buildUpSigmoidJRC: {
          text: "Built-up surface fraction (increasing share from 0.1 (left) to 1.0 (right))",
          start: "255,245,240,0",
          middle: "245, 81, 58, 1",
          end: "103,0,13,255"
        },
        buildUpSigmoidOSM: {
          text: "Built-up surface fraction (increasing share from 0.1 (left) to 1.0 (right))",
          start: "255,245,240,0",
          middle: "245, 81, 58, 1",
          end: "103,0,13,255"
        },
        greennessOSM: {
          text: "Greenness (increasing share from 0 (left) to 100 (right))",
          start: "247,252,245,0",
          middle: "116, 196, 118, 255",
          end: "0,68,27,255"
        }
      },
      vectorStyles: {
        gemeinden_statistics: function () {
          return {
            fill: false,
            color: "#000000",
            weight: 1,
          }
        }
      },
      highlightedFeatureHover: { layerId: null, featureID: null },
      testAOIs: [],
      markers: []
    };
  },
  computed: {
    currentProduct() {
      return this.$store.getters.getCurrentProduct;
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
      }
      return false
    }
  },
  mounted() {
    this.initMap();
    this.setView();

    var ref = this;

    eventBus.$on("AOI-CLICK", function (payload) {
      ref.fitToAOI(payload);
    });

    eventBus.$on("REFRESH-VIEW", function () {
      ref.refreshView();
    });

  },
  methods: {
    /**
     * This function initializes a leaflet container
     */
    initMap() {
      this.map = L.map("map", { minZoom: 3 }).setView(
        this.mapConfiguration.center,
        this.mapConfiguration.zoom,
      );

      this.map.createPane("basemap");
      this.map.getPane("basemap").style.zIndex = 100;
      this.initBaseMaps();
      this.initSearchControl();
      this.initMapView();

      var scale = L.control.scale({
        metric: true,
        imperial: false,
        maxWidth: 300,
        position: "bottomright",
      });
      this.setOverlayLayers(this.map);
      //this.setTileLayer(this.map)

      scale.addTo(this.map);

      // Add marker icons test AOIs
      this.addTestAOIs();
    },

    /**
    *Adds the search bar to the map
    */
    initSearchControl() {
      const provider = new OpenStreetMapProvider();
      this.searchControl = new GeoSearchControl({
        provider: provider,
        style: 'bar',
        searchLabel: 'Search location'
      });
      this.map.addControl(this.searchControl);
    },
    /**
     * Initializes base maps
     */
    initBaseMaps() {
      for (var key in this.baseMaps) {
        if (this.baseMaps[key].type == "tileLayer") {
          this.baseMaps[key].mapObject = L.tileLayer(this.baseMaps[key].url, {
            maxZoom: this.mapConfiguration.maxZoom,
            attribution: this.baseMaps[key].attribution,
            pane: "basemap",
          });
        } else if (this.baseMaps[key].type == "bing") {
          this.baseMaps[key].mapObject = L.tileLayer.bing({
            maxZoom: this.mapConfiguration.maxZoom,
            bingMapsKey: this.baseMaps[key].bingKey,
            attribution: this.baseMaps[key].attribution,
          }
          );
        }
        if (this.baseMaps[key].active)
          this.baseMaps[key].mapObject.addTo(this.map);
      }
    },
    /**
    * Sets the view point to the user's geo location.
    */
    initMapView() {
      this.map.setView([15, 0], 2.0);
    },
    initListItem() {
      this.testAOIs = aoiData;
    },
    disableMap() {
      this.map.dragging.disable();
      this.map.doubleClickZoom.disable();
      this.map.scrollWheelZoom.disable();
    },

    enableMap() {
      this.map.dragging.enable();
      this.map.doubleClickZoom.enable();
      this.map.scrollWheelZoom.enable();
    },
    setBaseMap(mapId) {
      for (var key in this.baseMaps) {
        if (this.baseMaps[key].active) {
          this.map.removeLayer(this.baseMaps[key].mapObject);
          this.baseMaps[key].active = false;
        }
      }
      this.baseMaps[mapId].mapObject.addTo(this.map);
      this.baseMaps[mapId].active = true;
    },
    getBaseMapAttribution() {
      for (var key in this.baseMaps) {
        if (this.baseMaps[key].active) {
          return this.baseMaps[key].attribution + "<br>Projection: EPSG 3857";
        }
      }
      return "";
    },
    setHighlightHover(layerId, id) {
      var style = {
        fill: true,
        fillColor: "#000000",
        fillOpacity: 0.2,
        weight: 3,
        color: "#000000",
      };

      if (id) {
        // set style of hilighted features
        this.overlayMaps[layerId].mapObject.setFeatureStyle(id, style);
        this.highlightedFeatureHover = {
          layerId: layerId,
          featureID: id,
        };
      }
    },
    setOverlayLayers(map) {
      var ref = this;
      for (var key in this.overlayMaps) {
        const layerId = key;
        const element = this.overlayMaps[layerId];

        // create panes for z-level ordering of overlay layers
        map.createPane(layerId);
        map.getPane(layerId).style.zIndex = element.zLevel;

        // create overlay map layers
        if (element.type == 'raster') {
          element.mapObject = L.tileLayer(element.url, {
            attribution: "© by GeoVille",
            minNativeZoom: 1,
            maxZoom: 19,
            maxNativeZoom: 14,
            pane: layerId,
          })
            .on("loading", function () {
              ref.map.spin(true);
              element.readyForReport = false;
            })
            .on("load", function () {
              ref.map.spin(false);
              element.readyForReport = true;
            });
        }
        else if (element.type == 'vector') {
          element.mapObject = new L.vectorGrid.protobuf(
            element.url,
            this.getVectorTileLayerOptions(layerId)
          )
            .on("mouseover", function (e) {
              if (!ref.isMobile) {
                ref.setHighlightHover(
                  layerId,
                  e.layer.properties[element.uniqueIdKey],
                );
              }
            })
            .on("mouseout", function () {
              if (!ref.isMobile) {
                ref.resetHighlightHover();
              }
            })
            .on("click", function (e) {
              if (ref.isMobile) {
                ref.resetHighlightHover();
                ref.setHighlightHover(
                  layerId,
                  e.layer.properties[element.uniqueIdKey],
                );
              }
              var properties = e.layer.properties;
              var x = '<h3>' + properties['gemeinde'] + '<h3><br/>' +
                '<dd class="font-sidebar-key">' + 'Mean Temperature' + '</dd>' + '<dt class="font-sidebar-value mb-2">' + properties['mean_temp'].toFixed(2) + '&#8451;' + '</dt>' +
                '<dd class="font-sidebar-key">' + 'Maximum Temperature' + '</dd>' + '<dt class="font-sidebar-value mb-2">' + properties['max_temp'].toFixed(2) + '&#8451;' + '</dt>' +
                '<dd class="font-sidebar-key">' + 'Minimum Temperature' + '</dd>' + '<dt class="font-sidebar-value mb-2">' + properties['min_temp'].toFixed(2) + '&#8451;' + '</dt>' +
                '<dd class="font-sidebar-key">' + 'Population exposed to<br> surface temperature >25°' + '<div class="tooltip">&#9432;</span><span class="tooltiptext">The parameter shows the percentage of the residential population exposed to areas with a surface temperature above 25°, the point at which most people start feeling subjective heat stress.</div>' + '</dd>' + '<dt class="font-sidebar-value mb-2">' + properties['percentage'].toFixed(2) + '</dt>'
              this.bindPopup(x);
              L.popup().setLatLng(e.latlng).openOn(this);
            })
        }
      }
    },
    getVectorTileLayerOptions(layerId) {
      // specifying the vector tile layer options
      var ref = this;

      var options = {
        rendererFactory: L.canvas.tile,
        vectorTileLayerStyles: ref.vectorStyles,
        interactive: true,
        getFeatureId: function (f) {
          return f.properties[ref.overlayMaps[layerId].uniqueIdKey];
        },
        pane: layerId,
      };
      return options;
    },
    resetHighlightHover() {
      if (
        this.highlightedFeatureHover.layerId != null &&
        this.highlightedFeatureHover.featureID
      ) {
        this.overlayMaps[
          this.highlightedFeatureHover.layerId
        ].mapObject.resetFeatureStyle(this.highlightedFeatureHover.featureID);
      }
      this.highlightedFeatureHover = { layerId: null, featureID: null };
    },
    updateOverlayMapsEvent(id, e) {
      e.cancelBubble = true;
      this.updateOverlayMaps(id);
    },
    updateOverlayMaps(id) {
      for (var key in this.overlayMaps) {
        if (key == id) {
          var item = this.overlayMaps[key];
          if (item.displayed) {
            this.map.removeLayer(item.mapObject);
            item.displayed = false;
            this.map.spin(false);
          } else {
            item.mapObject.addTo(this.map);
            item.displayed = true;
          }
        }
      }
      this.udpatePaneZLevels();
    },
    udpatePaneZLevels() {
      for (var item in this.overlayMaps) {
        this.map.getPane(item).style.zIndex = this.overlayMaps[item].zLevel;
      }
    },
    setOpacity(id) {
      this.overlayMaps[id].mapObject.setOpacity(
        this.overlayMaps[id].opacity / 100
      );
    },
    setView() {
      this.updateOverlayMaps('bezirke')
      this.updateOverlayMaps('buildUpNonResOSM')
      this.updateOverlayMaps('buildUpSigmoidOSM')
      this.overlayMaps['buildUpNonResOSM'].opacity = 100;
      this.overlayMaps['buildUpSigmoidOSM'].opacity = 100;
      this.setOpacity('buildUpNonResOSM');
      this.setOpacity('buildUpSigmoidOSM');
    },
    addTestAOIs() {
      // Load data for sample aois
      this.testAOIs = aoiData.data;

      var style = {
        color: "#dc3545",
        weight: 2,
        //"opacity": 0.65,
        fill: false
      };

      this.testAOIs.forEach((element) => {
        var aoiBoundaryLayer = new L.geoJSON(element.features, {
          options: { interactive: false },
          style: style,
        });
        this.map.addLayer(aoiBoundaryLayer);
        console.log(element)

        let poly = L.polygon(element.features[0].geometry.coordinates);

        let marker = L.marker([poly.getBounds().getCenter().lng, poly.getBounds().getCenter().lat])
          .addTo(this.map)
          .on('click', (event) => this.markerClick(event));

        var tooltip = L.tooltip({
          permanent: false,
        }).setContent('<b>' + element.viewName + '</b>');
        marker.bindTooltip(tooltip);

        this.markers.push(marker);
      });
    },
    markerClick(e) {
      let markerInd;
      for (let index = 0; index < this.markers.length; index++) {
        const element = this.markers[index];
        if (element._leaflet_id === e.target._leaflet_id) {
          markerInd = index;
        }
      }
      var geomObject = L.geoJSON(this.testAOIs[markerInd].features[0]);
      this.map.fitBounds(geomObject.getBounds());
    },
    fitToAOI(aoi) {
      var geomObject = L.geoJSON(aoi.features[0].geometry);
      this.map.fitBounds(geomObject.getBounds());
    },
    refreshView() {
      this.map.setView([15, 0], 2.0);
    }
  },
  watch: {
    currentProduct(productValue) {
      console.log("change product to: ", productValue);
    },
  },
};
</script>

<style>
#map {
  width: auto;
  height: 100%;
  position: relative;
  font-family: 'Roboto', sans-serif !important;
}

#layermenu {
  position: absolute;
  z-index: 1500;
  right: 9px;
  top: 9px;
  display: block;

}

.legend {
  text-align: left;
  line-height: 18px;
  color: #555;
  padding: 6px 8px;
  background: white;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 100%;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.legend .colorcircle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  opacity: 1;
  border: 1px solid;
  border-color: #888888;
}

.legend-padding-header {
  padding-bottom: 8px;
}

.legend-padding-items {
  padding-bottom: 2px;
}

.basemap-icon {
  border-radius: 50% !important;
  display: inline-block;
  width: 50px;
  height: 50px;
  background: white;
}

.basemap-icon:hover {
  cursor: pointer;
}

#map-attribution {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
}

.logos {
  padding: 5px;
  /* background: white;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); */
  border-radius: 5px;
  text-align: -webkit-center;
  padding-bottom: 15px;
}

#overlayCard .v-expansion-panel-header {
  padding: 0px;
}

.HeaderLabel {
  font-size: 18px;
}
</style>

<style>
.font-sidebar-value {
  font-size: 12px;
}

.font-sidebar-key {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 0px;
}

.tooltip {
  position: relative;
  display: inline-block;
  text-transform: lowercase !important;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: rgb(88, 88, 88);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
  text-transform: none !important;
  width: 300px;
}

/* Leaflet crispness override */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;

  /* Preserve crisp pixels with scaled up images */
  image-rendering: optimizeSpeed;
  /* Legal fallback */
  image-rendering: -moz-crisp-edges;
  /* Firefox        */
  image-rendering: -o-crisp-edges;
  /* Opera          */
  image-rendering: -webkit-optimize-contrast;
  /* Safari         */
  image-rendering: optimize-contrast;
  /* CSS3 Proposed  */
  image-rendering: crisp-edges;
  /* CSS4 Proposed  */
  image-rendering: pixelated;
  /* CSS4 Proposed  */
  -ms-interpolation-mode: nearest-neighbor;
  /* IE8+           */
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
