<template>
  <v-app id="app">
    <StartupDialog />
    <MobileDialog />
    <InformationDialog />

    <v-main>
      <NavigationDrawer v-if="!isMobile" />
      <v-app-bar app permanent v-if="!isMobile">
        <div>
          <v-row style="max-height: 64px">
            <v-col cols="2"></v-col>
            <v-col style="max-height: 64px" cols="1">
              <a href="https://joint-research-centre.ec.europa.eu/index_en" target="_blank" style="max-height: 64px">
                <v-img src="logo-jrc.png" class="fill-height" contain />
              </a>
            </v-col>
            <v-col style="max-height: 64px" cols="1">
              <a href="https://joint-research-centre.ec.europa.eu/index_en" target="_blank" style="max-height: 64px">
                <v-img src="logo-em.png" class="fill-height" contain></v-img>
              </a>
            </v-col>
            <v-col style="max-height: 64px" cols="1">
              <a href="https://joint-research-centre.ec.europa.eu/index_en" target="_blank" style="max-height: 64px">
                <v-img src="logo-geoville2.png" class="fill-height" contain></v-img>
              </a>

            </v-col>
            <v-col style="max-height: 64px" cols="1">
              <a href="https://joint-research-centre.ec.europa.eu/index_en" target="_blank" style="max-height: 64px">
                <v-img src="logo_cls.png" class="fill-height" contain></v-img>
              </a>

            </v-col>
            <v-col style="max-height: 64px" cols="1">
              <a href="https://www.vypno.ai/" target="_blank" style="max-height: 64px">
                <v-img src="logo_vypno.png" class="fill-height" contain></v-img>
              </a>

            </v-col>
            <v-col style="max-height: 64px" cols="1">
              <a href="https://joint-research-centre.ec.europa.eu/index_en" target="_blank" style="max-height: 64px">
                <v-img src="logo_dlr.png" class="fill-height" contain></v-img>
              </a>
            </v-col>
            <v-col style="max-height: 64px" cols="1">
              <a href="https://joint-research-centre.ec.europa.eu/index_en" target="_blank" style="max-height: 64px">
                <v-img src="logo_gaf.png" class="fill-height" contain></v-img>
              </a>
            </v-col>
            <v-col cols="2"></v-col>
          </v-row>
        </div>
      </v-app-bar>
      <AppBar v-if="isMobile" />
      <div id="map-container">
        <Viewer2D ref="map" />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Viewer2D from "./components/Viewer2D.vue";
import NavigationDrawer from "./components/NavigationDrawer";
import StartupDialog from "./components/StartupDialog.vue";
import InformationDialog from "./components/InformationDialog.vue";
import MobileDialog from "./components/MobileDialog.vue";
import AppBar from "./components/AppBar.vue";


export default {
  name: "App",
  components: {
    NavigationDrawer,
    Viewer2D,
    StartupDialog,
    InformationDialog,
    MobileDialog,
    AppBar
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
      }
      return false
    }
  },
  mounted() {
    this.$store.commit("setStartupDialog", true);
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': this.$store.commit("setMobileDialog", true); break;
      case 'sm': this.$store.commit("setMobileDialog", true); break;
    }
  },
  data: () => ({
  }),
  watch: {
  },
};
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e8eaf5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00526e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00a7e3;
}

html {
  overflow-y: hidden;
}
</style>

<style scoped>
#map-container {
  width: 100%;
  height: 100%;
}
</style>