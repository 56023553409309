<template>
  <div>
    <v-navigation-drawer app left :mini-variant="mini" permanent>
      <v-list-item link style="height:64px">
        <v-list-item-icon @click="logout()">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-logout</v-icon>
            </template>
            <span>Logout</span>
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item link>
        <v-list-item-icon @click="openInformation()">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
            </template>
            <span>About</span>
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-title>About</v-list-item-title>
      </v-list-item>

      <v-menu top>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item link>
            <v-list-item-icon>
              <v-tooltip right>
                <template #activator="tooltip">
                  <v-icon v-bind="attrs" v-on="{ ...on, ...tooltip.on }">mdi-map-marker-radius</v-icon>
                </template>
                <span>AOI</span>
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-title>AOI</v-list-item-title>
          </v-list-item>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in items" :key="index" link>
            <v-list-item-title @click="listItemClick(item)">{{ item.viewName }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-list-item link>
        <v-list-item-icon @click="refreshMapView()">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-refresh</v-icon>
            </template>
            <span>Refresh</span>
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-title>Refresh</v-list-item-title>
      </v-list-item>

    </v-navigation-drawer>
  </div>
</template>

<script>
import Vue from "vue";
import { eventBus } from '@/main.js';
import * as aoiData from "@/assets/testData.json";

export default {
  name: "NavigationDrawer",
  components: {},
  data() {
    return {
      mini: true,
      items: [],
    };
  },
  mounted() {
    this.items = aoiData.data
  },
  methods: {
    listItemClick(item) {
      console.log("list item clicked: ", item);
      eventBus.$emit("AOI-CLICK",item);
    },
    logout() {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      Vue.$keycloak.logout({ redirectUri: "http://localhost:8080/" });
    },
    openInformation() {
      this.$store.commit("setInformationDialog", true);
      document.getElementById("map").focus(); // Note: his is used to get rid of the gray backgrouns issue after the click event
    },
    refreshMapView() {
      eventBus.$emit("REFRESH-VIEW");
    }
  },
};
</script>

<style scoped>
.fixedBottom {
  position: fixed !important;
  bottom: 0 !important;
  width: 100%;
}
</style>
 