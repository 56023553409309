<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" @click:outside="closeDialog()">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">INTRO</v-card-title>
        <v-card-text style="text-align: justify">
          <h4 class="pt-4">Copernicus Global Human Settlement Layer (GHSL) test area products</h4>
          <br>
          <ul>
  <li><b>Zoom</b> in on specific AOIs for closer inspection </li>
  <li><b>Click</b> on the layers to activate/deactivate the layers and the legend. </li>
  <li><b>Navigate</b> through the preliminary Copernicus GHSL products. </li>
</ul>
<br>
<p>The data viewer provides an overview of the results produced for the five sample test areas in the framework of the GHSL technical offer. 

Per test area the following three Copernicus GHSL products for the reference year 2022 are displayed: </p>
<ul>
  <li>the <b>Built-up surface fraction (BUFRAC)</b> </li>
  <li>the <b>Residential & Non-residential (RES/NRES)</b> layer </li>
  <li>the <b>Greenness</b> layer </li>
</ul>
<br>
<p style="font-size:10px">Test mapping produced by GeoVille and CLS in response to Call for tender JRC/IPR/2022/OP/1365 (Implementation and Operation of the Copernicus Emergency Management Service - Exposure Mapping Component - Copernicus GLOBAL HUMAN SETTLEMENT LAYER (GHSL), including raster products based on Satellite input data from 2021 and 2022) 
<br><br>This Application is optimised for a screen resolution of 1920x1080 or higher and was tested with Google Chrome and Firefox. </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
export default {
  name: "StartupDialog",
  components: {},
  data() {
    return {};
  },
  computed: {
    dialog() {
      return this.$store.getters.getStartupDialog;
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit("setStartupDialog", false);
    },
  },
};
</script>

<style scoped>
</style>