import Vue from 'vue'
import Keycloak from 'keycloak-js'

let initOptions = {
  url: "https://authorize.geoville.com/",
  realm: "ghsl_proposal",
  clientId: "ghsl_proposal_ui",
  cors2: true,
};

const _keycloak = new Keycloak(initOptions)

const Plugin = {
  install: Vue => {
      Vue.$keycloak = _keycloak;
  },
};

Plugin.install = Vue => {
  Vue.$keycloak = _keycloak;
  Object.defineProperties(Vue.prototype, {
      $keycloak: {
        get() {
          return _keycloak;
        },
    },
  });
};

Vue.use(Plugin);

export default Plugin;